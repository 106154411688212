import { QueryClient, type QueryClientConfig, type useQuery } from '@tanstack/react-query';
import { orderBy } from 'lodash';
import { ACTIVE_BROADCAST_CHANNEL_ID, type BroadcastChannelData, REACT_QUERY_CHANNEL_NAME } from './broadcast-channel';
import { MINUTE } from './time';

export const QUERY_KEY_BASES = [
  'addEntityAddress',
  'addEntityEmail',
  'addEntityPhone',
  'addPatientClinicalNote',
  'addPatientDocument',
  'addPatientProvider',
  'addPatientStatus',
  'addPod',
  'addPodAssignments',
  'addPodPatient',
  'addProvider',
  'addProviderSite',
  'addScheduledWorklistItem',
  'addWorklistItem',
  'assignPrimaryNurse',
  'bulkAssignPrimaryNurse',
  'checkPatientExists',
  'checkPrescriptionExists',
  'deleteEntityEmail',
  'deleteEntityPhone',
  'deletePatientProvider',
  'deletePod',
  'deletePodAssignment',
  'deletePodPatient',
  'deletePrescription',
  'deletePurchaseOrder',
  'deleteScheduledWorklistItem',
  'getAssignmentsTableData',
  'getCustomer',
  'getCustomerGraph',
  'getCustomerLookup',
  'getCustomers',
  'getDeviceStatusTypes',
  'getDeviceUsage',
  'getEntityAddresses',
  'getEntityEmails',
  'getEntityPhones',
  'getEscalation',
  'getEscalationItemsByAssignedToUserId',
  'getEscalationStatusTypes',
  'getEscalationTypes',
  'getGenders',
  'getImage',
  'getInactiveReasons',
  'getInteractionTypes',
  'getLastCalledPatientsByPrimaryNurseId',
  'getLastCalledPatientsByPrimaryNurseIdDetails',
  'getLinesOfBusiness',
  'getModesOfContact',
  'getNextWorklistItems',
  'getPatient',
  'getPatientAddresses',
  'getPatientAssignments',
  'getPatientCurrentStatus',
  'getPatientDevice',
  'getPatientDeviceBySerialNumber',
  'getPatientDocuments',
  'getPatientEmails',
  'getPatientEscalationDetails',
  'getPatientEscalationProviders',
  'getPatientEscalationSummary',
  'getPatientExport',
  'getPatientHistoryQuestions',
  'getPatientInteractionForms',
  'getPatientPhones',
  'getPatientPods',
  'getPatientPrescription',
  'getPatientProvider',
  'getPatientProviders',
  'getPatientPurchaseOrders',
  'getPatientsTableData',
  'getPatientStatuses',
  'getPatientSummary',
  'getPatientWorklistItemHistory',
  'getPatientWorklistItems',
  'getPodAssignments',
  'getPodInfo',
  'getPodPatients',
  'getPodPatientsTable',
  'getPodRoles',
  'getPodsAndAssignments',
  'getPrescribingProvider',
  'getPrescription',
  'getPrescriptionTableData',
  'getProvider',
  'getProviderAddresses',
  'getProviderEmails',
  'getProviderPhones',
  'getProvidersTableData',
  'getProvidersTableDataByPatientSite',
  'getProviderTypes',
  'getPurchaseOrder',
  'getPurchaseOrderByPatientId',
  'getPurchaseOrdersByOrderNumber',
  'getPurchaseOrdersTableData',
  'getReleases',
  'getScheduledWorklistItems',
  'getSite',
  'getSiteReference',
  'getSites',
  'getSitesTableData',
  'getStates',
  'getTimeZones',
  'getUnassignedPods',
  'getUnassignedPodsCount',
  'getUploadedPurchaseOrdersTableData',
  'getUsers',
  'getUsersByPodRole',
  'getVAProvidersTableDataByPatientSite',
  'getWorklistItem',
  'getWorklistItemsSummaryByTypeAndPodAssignedToUserId',
  'getWorklistItemStatusTypes',
  'getWorklistItemTypes',
  'getWorklistTableData',
  'getWorklistTypes',
  'lookupNPI',
  'newFetchLookup',
  'postImage',
  'postProviderIdentifier',
  'saveEditDeviceEvent',
  'saveNewDeviceEvent',
  'savePrescription',
  'savePurchaseOrder',
  'searchNPI',
  'sendEmail',
  'sendProviderPreview',
  'setRelease',
  'unVoidPrescription',
  'unVoidPurchaseOrder',
  'updateCustomer',
  'updateDeviceSerialNumber',
  'updateEntityAddress',
  'updateEntityEmail',
  'updateEntityPhone',
  'updateEscalation',
  'updatePatient',
  'updatePatientDocument',
  'updatePatientInteractionForm',
  'updatePatientPrescription',
  'updatePatientPrimaryNurse',
  'updatePatientProvider',
  'updatePatientPurchaseOrder',
  'updatePod',
  'updatePrescription',
  'updateProvider',
  'updatePurchaseOrder',
  'updateSite',
  'updateWorklistItem',
  'uploadPurchaseOrderFileServer',
  'voidPrescription',
  'voidPrescriptionEdit',
  'voidPurchaseOrder',
  'voidPurchaseOrderEdit'
] as const;
export type QueryKeyBase = (typeof QUERY_KEY_BASES)[number];

const defaultOptions: QueryClientConfig['defaultOptions'] = { queries: { staleTime: 5 * MINUTE, retry: 1 } };
export const queryClient = new QueryClient({ defaultOptions });

const channel = new BroadcastChannel(REACT_QUERY_CHANNEL_NAME);

export const queryKey = <T>(queryKeyBase: QueryKeyBase, args: T = {} as T): [QueryKeyBase, ...string[]] => {
  return [
    queryKeyBase,
    ...orderBy(
      Object.keys(args || {})
        .filter((key) => (args || {})[key] !== undefined)
        .map((key) => `${key}:${(args || {})[key]}`)
    )
  ];
};

export const queryConfig = <T, U>(queryKeyBase: QueryKeyBase, method: (args: T) => Promise<U>, args: T = {} as T): Parameters<typeof useQuery<U>>[0] => {
  return {
    ...defaultOptions,
    queryKey: queryKey(queryKeyBase, args),
    queryFn: () => method(args),
    enabled: args ? Object.values(args).every((value) => value !== undefined) : true
  };
};

export const invalidateQueries = (queryKeys: [QueryKeyBase, ...string[]][]): void => {
  (queryKeys || []).forEach((queryKey) => queryClient.invalidateQueries({ queryKey }));

  const data: BroadcastChannelData = { channelId: ACTIVE_BROADCAST_CHANNEL_ID, type: 'invalidate_all' };
  channel.postMessage(data);
};

import { cn } from '@/utils/classes';
import type { FC } from 'react';
type Size = 'sm' | 'md' | 'lg';
type Variant = 'primary' | 'secondary' | 'destructive' | 'ghost' | 'outline';
export interface ButtonProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
  size?: Size;
  variant?: Variant;
  disabled?: boolean;
  loading?: boolean;
  type?: 'submit';
  id?: string;
}
const defaultButtonClasses: string = 'button shadow-button cursor-pointer mx-auto bg-podi-primary font-medium px-8 py-4 font-sans uppercase text-white duration-300 hover:bg-podi-primary-hover focus:outline-podi-primary focus:ring-2 focus:ring-podi-primary focus:ring-offset-1 focus:ring-offset-podi-primary disabled:bg-podi-primary-disabled disabled:shadow-none disabled:drop-shadow-none drop-shadow-md hover:drop-shadow-none hover:focus:ring-offset-0 hover:focus:ring-podi-primary-hover disabled:bg-podi-primary-disabled';
const SizeClassesMap: Record<Size, string> = {
  sm: 'w-auto mx-0 py-1 px-2 text-xs/5 rounded-md ',
  md: 'w-auto mx-0 p-2 text-sm/5 rounded-lg',
  lg: 'w-auto mx-0 p-3 text-lg/5 rounded-xl'
};
const VariantClassesMap: Record<Variant, string> = {
  primary: 'bg-podi-primary hover:bg-podi-primary-hover focus:ring-podi-primary focus:ring-offset-0',
  secondary: 'bg-podi-secondary hover:bg-podi-secondary-hover focus:ring-podi-secondary focus:ring-offset-0',
  destructive: 'bg-red-500 hover:bg-[#31060A] disabled-[#F7C9CE]',
  ghost: 'bg-transparent hover:bg-gray-100 text-primary focus:ring-podi-primary focus:ring-offset-0',
  outline: 'bg-transparent border border-podi-primary text-primary hover:bg-gray-100 focus:ring-podi-primary focus:ring-offset-0'
};
export const getButtonClasses = ({
  size,
  variant,
  disabled
}: {
  size: Size;
  variant: Variant;
  disabled: boolean;
}): string => {
  const sizeClasses = size ? SizeClassesMap[size] : '';
  const variantClasses = variant ? VariantClassesMap[variant] : '';
  return cn(defaultButtonClasses, sizeClasses, variantClasses, {
    'cursor-not-allowed': disabled
  });
};
export const Button: FC<ButtonProps> = ({
  size = 'md',
  variant = 'primary',
  className,
  disabled = false,
  loading,
  children,
  type,
  id,
  ...props
}) => {
  return <button id={id} disabled={loading || disabled} type={type} className={cn(getButtonClasses({
    size,
    variant,
    disabled
  }), className)} {...props} data-sentry-component="Button" data-sentry-source-file="Button.tsx">
      {children}
    </button>;
};
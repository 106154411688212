import { type AchillesUser } from '@/schemas/users';
import { cn } from '@/utils/classes';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { type Url } from 'next/dist/shared/lib/router/router';
import Image from 'next/image';
import Link from 'next/link';
import type { FC } from 'react';
import { Fragment } from 'react';
export interface DropdownMenuItem {
  name: string;
  action?: () => void;
  href?: Url;
  className?: string;
}
interface DropdownMenuProps {
  id?: string;
  user?: AchillesUser;
  items: DropdownMenuItem[];
  menuButton?: React.ReactNode | boolean;
  className?: string;
  menuButtonClasses?: string;
  offsetTop?: string;
}
export const DropdownMenu: FC<DropdownMenuProps> = ({
  id,
  user,
  items = [],
  menuButton,
  className,
  menuButtonClasses
}) => {
  return <Menu id={id} as="div" className={`relative `} data-sentry-element="Menu" data-sentry-component="DropdownMenu" data-sentry-source-file="DropdownMenu.tsx">
      <Menu.Button as="button" className={`${menuButtonClasses}`} data-sentry-element="unknown" data-sentry-source-file="DropdownMenu.tsx">
        {user ? <div className={`-m-1.5 flex items-center p-1.5 `}>
            <span className="sr-only">Open user menu</span>
            <Image className="h-8 w-8 rounded-full bg-gray-50" src={user.avatarUrl || 'https://cdn-icons-png.flaticon.com/512/149/149071.png'} width={48} height={48} alt="User profile image" priority />
            <span className="hidden lg:flex lg:items-center">
              <span className="ml-4 text-sm font-semibold leading-6" aria-hidden="true">
                {user.firstName}
              </span>
              <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </div> : menuButton}
      </Menu.Button>
      <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95" data-sentry-element="Transition" data-sentry-source-file="DropdownMenu.tsx">
        <Menu.Items as="div" className={cn('absolute right-0 z-50 min-w-[9rem] origin-top-right rounded-md bg-white py-1.5 shadow-lg ring-1 ring-gray-900/5 focus:outline-none', className)} data-sentry-element="unknown" data-sentry-source-file="DropdownMenu.tsx">
          {items.map(item => <Menu.Item as="div" key={item.name}>
              {({
            active
          }) => <Link href={`${item.href}`} onClick={() => item?.action?.()} className={cn({
            'bg-podi-primary text-white': active && !!item.action
          }, item?.action ? 'cursor-pointer' : 'hover: cursor-default', 'block px-3 py-1 text-base leading-6 transition-all', item.className)}>
                  {item.name}
                </Link>}
            </Menu.Item>)}
        </Menu.Items>
      </Transition>
    </Menu>;
};
import { cn } from '@/utils/classes';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useRef, type FC } from 'react';
import { createPortal } from 'react-dom';
import { LineLoading } from './LineLoading';
export interface ModalDialogProps {
  id: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  title?: string;
  className?: string;
  children?: React.ReactNode;
  loading?: boolean;
  closeButtonClassname?: string;
  closeIconClassname?: string;
  closeIcon?: React.ReactNode;
  panelClasses?: string;
  headerClasses?: string;
  titleClasses?: string;
  isFullScreen?: boolean;
  isCrisisMode?: boolean;
  parentIsModal?: boolean;
  isLegacyModal?: boolean;
}
export const ModalDialog: FC<ModalDialogProps> = ({
  id,
  isOpen,
  setIsOpen,
  className,
  title,
  children,
  loading,
  closeButtonClassname,
  closeIconClassname,
  closeIcon,
  panelClasses,
  headerClasses,
  titleClasses,
  isFullScreen,
  isCrisisMode,
  parentIsModal = false,
  isLegacyModal = true
}) => {
  const panelRef = useRef<HTMLDivElement>(null);
  const closeButtonDefaultClasses = 'border-1 group fixed right-6 top-5 z-[51] mx-0 my-auto cursor-pointer rounded-full border border-white bg-podi-gray/50 p-1.5 drop-shadow-lg transition-all duration-300 hover:bg-podi-gray/75 hover:drop-shadow-none';

  // legacy pattern
  if (isFullScreen || isLegacyModal) {
    return <Transition.Root show={isOpen} as={Fragment}>
        <Dialog ref={panelRef} id={id} open={isOpen} initialFocus={panelRef} onClose={() => null} /* stop the dialog from closing on overlay click */ className={cn('fixed inset-0 z-50 overflow-y-auto backdrop-blur-sm', {
        'z-[52]': parentIsModal
      })}>
          <div className="flex min-h-screen items-center justify-center">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Dialog.Overlay className="pointer-events-none fixed inset-0 bg-black/45" />
            </Transition.Child>

            <Transition.Child as={Fragment} enter="ease-in duration-300 transform" enterFrom="translate-y-4" enterTo="translate-y-0" leave="ease-out duration-200 transform" leaveFrom="translate-y-0" leaveTo="translate-y-4">
              <div className={cn('relative h-full w-full rounded-lg shadow-lg drop-shadow-2xl md:w-1/2 lg:w-1/3', {
              'min-h-screen animate-fade-up animate-duration-300 animate-ease-in-out md:w-full lg:w-full': isFullScreen
            }, className)}>
                {isFullScreen ? createPortal(<div className={cn(closeButtonDefaultClasses, {
                'right-8 top-6 animate-fade-up animate-duration-300 animate-ease-in-out': isFullScreen
              }, closeButtonClassname)} onClick={() => setIsOpen(false)}>
                      {closeIcon ? closeIcon : <XMarkIcon className={cn('h-4 w-4 text-white', closeIconClassname)} title={`Close ${title} Dialog`} aria-label={`Close ${title} Dialog`} />}
                    </div>, document.body) : <div className={cn(closeButtonDefaultClasses, closeButtonClassname)} onClick={() => setIsOpen(false)}>
                    {closeIcon ? closeIcon : <XMarkIcon className={cn('h-4 w-4 text-white', closeIconClassname)} title={`Close ${title} Dialog`} aria-label={`Close ${title} Dialog`} />}
                  </div>}

                {!isFullScreen && !isCrisisMode ? <div className={cn('modal-header flex items-center rounded-t-lg bg-gradient-to-r from-podi-primary-hover to-podi-primary py-6', headerClasses)}>
                    <Dialog.Title className={cn('modal-title px-6 text-lg font-semibold text-white', titleClasses)}>{title}</Dialog.Title>
                  </div> : null}
                {!isFullScreen && isCrisisMode ? <div className={cn('modal-header flex items-center rounded-t-lg bg-gradient-to-r from-podi-lightred-hover to-podi-lightred py-6', headerClasses)}>
                    <Dialog.Title className={cn('modal-title px-6 text-lg font-semibold text-white', titleClasses)}>{title}</Dialog.Title>
                  </div> : null}
                {loading && <LineLoading />}
                <Dialog.Panel ref={panelRef} className={cn('modal-panel max-h-[80vh] rounded-b-lg bg-white px-6 pb-6 pt-4', {
                'h-full min-h-screen rounded-t-lg px-2 pb-2 pt-2': isFullScreen
              }, panelClasses)}>
                  {children}
                </Dialog.Panel>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>;
  } else {
    return <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" ref={panelRef} initialFocus={panelRef} onClose={() => setIsOpen(false)}>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black/45 backdrop-blur-sm" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className={cn('flex min-h-full items-center justify-center p-4 text-center', className)}>
              <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                <Dialog.Panel className={cn('w-full max-w-md transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all', className)}>
                  <div className={cn('modal-header flex items-center rounded-t-lg bg-gradient-to-r from-podi-primary-hover to-podi-primary py-6', headerClasses)}>
                    <Dialog.Title className={cn('modal-title px-6 text-lg font-semibold text-white', titleClasses)}>{title}</Dialog.Title>
                    <button className={cn(closeButtonDefaultClasses, closeButtonClassname)} onClick={() => setIsOpen(false)}>
                      <XMarkIcon className={cn('h-4 w-4 text-white', closeIconClassname)} title={`Close ${title} Dialog`} aria-label={`Close ${title} Dialog`} />
                    </button>
                  </div>
                  <div className="overflow-auto p-6">{children}</div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>;
  }
};
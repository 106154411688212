import { cn } from '@/utils/classes';
import { type Dispatch, type SetStateAction } from 'react';
import { useController, type FieldValues, type UseControllerProps } from 'react-hook-form';
import { FormLabel } from './FormLabel';
type ControlledTextInputType<K> = {
  containerClassName?: string;
  readOnly?: boolean;
  name: string;
  formId: string;
  placeHolderText: string;
  stateValue: K;
  inputClassName?: string;
  label?: string;
  labelId?: string;
  setStateValue: Dispatch<SetStateAction<K>>;
  onChangeHelper?: (current: string, prev: K) => K;
};

/**
 * ControlledTextInput used with React Hook Forms (RHF)
 * @example
 * name: input form name used in RHF
 * formId: html id
 * stateValue, setStateValue: external form state
 * control: control object from useForm
 * containerClassName?: container className
 * inputClassName?: input className
 * label?: option label
 * onChangeHelper?: Helper function for onChange event
 */
export const ControlledTextInput = <T extends FieldValues, K>(props: UseControllerProps<T> & ControlledTextInputType<K>) => {
  const {
    containerClassName,
    formId,
    inputClassName,
    placeHolderText,
    label,
    labelId,
    stateValue,
    setStateValue,
    readOnly = false,
    onChangeHelper,
    ...controllerProps
  } = props;
  const required = controllerProps?.rules?.required;
  const {
    field,
    fieldState
  } = useController(controllerProps);
  const {
    invalid,
    error
  } = fieldState;
  return <div className={containerClassName} data-sentry-component="ControlledTextInput" data-sentry-source-file="ControlledTextInput.tsx">
      {label && <FormLabel id={labelId}>
          {label}
          {required ? ' *' : ''}
        </FormLabel>}
      <input readOnly={readOnly} id={formId} type="text" {...field} placeholder={placeHolderText || controllerProps?.name} onChange={event => {
      if (onChangeHelper) {
        const results = onChangeHelper(event.target.value, stateValue);
        field.onChange(results); // data send back to hook form
        setStateValue(results);
        return;
      }
      field.onChange(event.target.value); // data send back to hook form
      if (setStateValue) setStateValue(event.target.value as typeof stateValue); // UI state
    }} className={cn('block w-full appearance-none rounded-md border border-gray-300 bg-white px-3 py-1.5 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-podi-primary sm:text-sm sm:leading-6', {
      'border-red-500 focus:border-red-500 focus:ring-red-500': invalid
    }, inputClassName)} />

      {invalid && error && <p className="ml-4 mt-2 text-sm text-red-500">{fieldState?.error?.message || 'Please fill this out'}</p>}
    </div>;
};
import { cn } from '@/utils/classes';
import { useEffect, useRef, useState, type Dispatch, type SetStateAction } from 'react';
import { useController, type FieldValues, type UseControllerProps } from 'react-hook-form';
import { FormLabel } from './FormLabel';
type ControlledTextInputType = {
  rows?: number;
  containerClassName?: string;
  subContainerClassName?: string;
  name: string;
  icon?: React.ReactNode;
  stateValue: string;
  inputClassName?: string;
  label?: string;
  labelId?: string;
  setStateValue: Dispatch<SetStateAction<string>>;
};

/**
 * ControlledTextInput used with React Hook Forms (RHF)
 * @example
 * name: input form name used in RHF
 * row?: number of rows
 * formId: html id
 * stateValue, setStateValue: external form state
 * control: control object from useForm
 * containerClassName?: container className
 * subContainerClassName?: sub container className
 * inputClassName?: input className
 * label?: option label
 * icon?: Icon
 */
export const ControlledTextArea = <T extends FieldValues,>(props: UseControllerProps<T> & ControlledTextInputType) => {
  const {
    containerClassName,
    subContainerClassName,
    icon,
    rows,
    inputClassName,
    label,
    labelId,
    stateValue,
    setStateValue,
    ...controllerProps
  } = props;
  const {
    field,
    fieldState
  } = useController(controllerProps);
  const required = controllerProps?.rules?.required;
  const disabled = field?.disabled;
  const error = fieldState?.error;
  const textareaRef = useRef<any>(null);
  const [textareaHeight, _setTextareaHeight] = useState<string>('auto');
  useEffect(() => {
    if (textareaRef.current) {
      // Set the textarea's height to "auto" to reset it
      textareaRef.current.style.height = 'auto';

      // Set the textarea's height based on its scrollHeight
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  });
  const autoClamp = rows ? `line-clamp-${rows}` : null;
  return <div className={cn('flex w-full items-center', containerClassName)} data-sentry-component="ControlledTextArea" data-sentry-source-file="ControlledTextArea.tsx">
      {icon}
      <div className={cn('w-full', subContainerClassName)}>
        {label && <FormLabel id={labelId}>
            {label}
            {required ? ' *' : ''}
          </FormLabel>}
        <textarea value={stateValue} ref={textareaRef} rows={!rows ? 1 : rows} disabled={disabled} style={{
        height: textareaHeight
      }} onChange={e => {
        setStateValue(e.target.value);
      }} className={cn('block w-full resize-none appearance-none overflow-hidden rounded-md border border-gray-300 bg-white px-3 py-2 text-sm text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-podi-primary sm:text-sm sm:leading-6', {
        'border-red-500 focus:border-red-500 focus:ring-red-500': error
      }, {
        'focus-ring-0 bg-gray-50 shadow-none ring-0': disabled
      }, autoClamp, inputClassName)} />
        {error && <p className="text-sm text-red-500">{error?.message || 'Please fill this out'}</p>}
      </div>
    </div>;
};
import { cn } from '@/utils/classes';
import type { ComponentPropsWithoutRef, FC } from 'react';
interface FooterProps extends ComponentPropsWithoutRef<'footer'> {
  wrapperClasses?: string;
}
export const Footer: FC<FooterProps> = ({
  id,
  className,
  children,
  wrapperClasses,
  ...props
}) => {
  const currentYear = new Date().getFullYear();
  return <footer id={id} className={cn('w-full', className)} {...props} data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <h2 className="sr-only">Footer</h2>
      <div className={cn('border-t border-gray-300 py-3 text-center sm:mx-6 lg:mx-8', wrapperClasses)}>
        {children}
        <p className="cursor-default text-xs leading-5 text-gray-500">&copy;{` ${currentYear} Podimetrics.`}</p>
      </div>
    </footer>;
};
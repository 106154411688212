'use-client';

import { cn } from '@/utils/classes';
import { type FC } from 'react';
function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return <div className={cn('animate-pulse rounded-md bg-muted', className)} {...props} data-sentry-component="Skeleton" data-sentry-source-file="SkeletonLoading.tsx" />;
}
interface SkeletonLoadingProps {
  className?: string;
  linesLength: number;
}
export const SkeletonLoading: FC<SkeletonLoadingProps> = ({
  className,
  linesLength = 1
}) => {
  const newArray: number[] = new Array(linesLength).fill(2);
  return <div className={` flex flex-col ${className}`} data-sentry-component="SkeletonLoading" data-sentry-source-file="SkeletonLoading.tsx">
      <div className="h-10 w-full animate-pulse bg-gray-300"></div>

      {newArray.map((_item, key) => {
      return <div key={key} className="mt-4 h-7 w-full animate-pulse bg-gray-200"></div>;
    })}
    </div>;
};
export const InputSkeleton = (name: string) => {
  return <div className="flex min-h-[72px] w-full flex-col" data-sentry-component="InputSkeleton" data-sentry-source-file="SkeletonLoading.tsx">
      <div className="space-y-2">
        <p className="mb-2 block text-sm font-medium leading-3 text-gray-900">{name}</p>
        <Skeleton className="h-10 w-full" data-sentry-element="Skeleton" data-sentry-source-file="SkeletonLoading.tsx" />
      </div>
    </div>;
};
import { cn } from '@/utils/classes';
import { useState } from 'react';
import { type FieldError, type FieldErrorsImpl, type Merge } from 'react-hook-form';
import { FormLabel } from './FormLabel';
export interface TextInputProps extends React.ComponentPropsWithRef<'input'> {
  label?: string;
  error?: string;
  fieldError?: FieldError | Merge<FieldError, FieldErrorsImpl<{}>>;
  inputClassName?: string;
  required?: boolean;
  loading?: boolean;
  disabled?: boolean;
  type?: string;
  characterCount?: number;
  icon?: React.ReactNode;
  onClick?: () => void;
}
export function TextInput({
  id,
  value,
  label,
  error,
  fieldError,
  type,
  className,
  inputClassName,
  required,
  loading,
  onClick,
  disabled,
  icon,
  ...props
}: TextInputProps) {
  const [startedEditing, setStartedEditing] = useState(false);
  const inputPaddingClass = icon ? 'pl-10' : 'pl-3';
  return <div className={cn(className)} onClick={() => onClick?.()} data-sentry-component="TextInput" data-sentry-source-file="TextInput.tsx">
      {label && <FormLabel id={id}>
          {label}
          {required ? ' *' : ''}
        </FormLabel>}
      <div className="relative flex w-full items-center">
        {icon && <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">{icon}</div>}
        <input id={id} value={value || ''} type={type ? type : 'text'} required={required || false} onBlur={() => setStartedEditing(true)} disabled={disabled} {...props} className={cn(`block w-full appearance-none rounded-md border border-gray-300 bg-white text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-podi-primary disabled:pointer-events-none sm:text-sm sm:leading-6 ${inputPaddingClass}`, {
        'border-red-500 focus:border-red-500 focus:ring-red-500': startedEditing && error
      }, {
        'focus-ring-0 bg-gray-50 shadow-none ring-0': disabled || loading
      }, inputClassName)} />
      </div>
      {startedEditing && error && <p className="!mt-0 !p-1 !text-xs !text-red-500">{error}</p>}
      {fieldError && <p className="!mt-0 !p-1 !text-xs !text-red-500">{fieldError.message}</p>}
    </div>;
}
import { cn } from '@/utils/classes';
import Image from 'next/image';
import React from 'react';
interface AvatarProps {
  image?: string | null;
  name: string;
  className?: string;
  textClassName?: string;
}
export const Avatar: React.FC<AvatarProps> = ({
  image,
  name,
  className,
  textClassName
}) => {
  return <>
      {image ? <Image className={cn('inline-block rounded-full', className)} src={image} alt={name} width={40} height={40} /> : <span className={cn('inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-500', className)}>
          <span data-cy="avatar-text" className={cn('text-xs font-medium leading-none text-white', textClassName)}>
            {name.charAt(0)}
          </span>
        </span>}
    </>;
};
import { cn } from '@/utils/classes';
import { forwardRef, useState } from 'react';
import { type FieldError, type FieldErrorsImpl, type Merge } from 'react-hook-form';
import { FormLabel } from './FormLabel';
interface TextInputProps extends React.ComponentPropsWithRef<'input'> {
  label?: string;
  error?: string;
  fieldError?: FieldError | Merge<FieldError, FieldErrorsImpl<{}>>;
  inputClassName?: string;
  required?: boolean;
  loading?: boolean;
  disabled?: boolean;
  type?: string;
  characterCount?: number;
}
export const TextInputWithRef = forwardRef<HTMLInputElement, TextInputProps>(({
  id,
  label,
  error,
  fieldError,
  type,
  className,
  inputClassName,
  required,
  loading,
  disabled,
  characterCount,
  ...props
}, ref) => {
  const [startedEditing, setStartedEditing] = useState(false);
  return <div className={className}>
        {label && <FormLabel id={id}>
            {label}
            {required ? ' *' : ''}
          </FormLabel>}
        <input id={id} type={type ? type : 'text'} required={required || false} onBlur={() => setStartedEditing(true)} ref={ref} {...props} className={cn('my-1 block w-full appearance-none rounded-md border border-gray-300 bg-white px-3 py-1.5 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-podi-primary sm:text-sm sm:leading-6', {
      'border-red-500 focus:border-red-500 focus:ring-red-500': startedEditing && error
    }, {
      'focus-ring-0 pointer-events-none bg-gray-50 shadow-none ring-0': disabled || loading
    }, inputClassName)} />
        {startedEditing && error && <p className="ml-3 text-xs text-red-500">{error}</p>}
        {fieldError && <p className="!mt-0 !p-1 !text-xs !text-red-500">{fieldError.message}</p>}
      </div>;
});
TextInputWithRef.displayName = 'TextInputWithRef';
import { cn } from '@/utils/classes';
type LabelProps = React.ComponentPropsWithoutRef<'label'>;
export function FormLabel({
  id,
  children,
  className,
  ...props
}: LabelProps) {
  return <label htmlFor={id} className={cn('mb-2 block text-sm font-medium leading-3 text-gray-900', className)} {...props} data-sentry-component="FormLabel" data-sentry-source-file="FormLabel.tsx">
      {children}
    </label>;
}
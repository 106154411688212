export const isValidJSONString = (value: unknown): boolean => {
  if (!value) return false;

  try {
    JSON.parse(value as string);
  } catch (e) {
    return false;
  }

  return true;
};

export const JSONSafeParse = <T = any>(value: string): T => {
  return isValidJSONString(value) ? (JSON.parse(value) as T) : ({} as T);
};

export const pascalToSpaced = (text: string): string => {
  // Check if the first character is already uppercase
  const firstCharIsUppercase = /^[A-Z]/.test(text);

  // Replace uppercase letters with a space before them, except the first character
  const result = text.replace(/(?<!\b[A-Z])\B([A-Z])/g, ' $1');

  // Capitalize the first character if it's not already uppercase
  const capitalizedResult = firstCharIsUppercase ? result : result.charAt(0).toUpperCase() + result.slice(1);

  return capitalizedResult;
};

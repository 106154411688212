import { cn } from '@/utils/classes';
import { XMarkIcon } from '@heroicons/react/24/outline';
interface MissingSearchProp {
  additionalIconClass?: string;
  handleClearSearch: () => void;
  searchQuery: string;
  searchType: 'Patient' | 'Provider';
  className?: string;
}
export function MissingSearch({
  additionalIconClass = '',
  handleClearSearch,
  searchQuery,
  searchType,
  className
}: MissingSearchProp) {
  return <div className="relative" data-sentry-component="MissingSearch" data-sentry-source-file="MissingSearch.tsx">
      <div className={cn('flex w-full justify-end', className)}>
        <div className="rounded-full border bg-red-500 p-1.5 drop-shadow-lg transition-all duration-300 hover:bg-gray-700/75 hover:drop-shadow-none" onClick={() => handleClearSearch()}>
          <XMarkIcon className={cn('h-4 w-4 text-white', additionalIconClass)} title="Close dialog" data-sentry-element="XMarkIcon" data-sentry-source-file="MissingSearch.tsx" />
        </div>
      </div>
      <div className="relative flex flex-col gap-2 p-6 sm:min-w-[375px] sm:text-base">
        <p>
          Your search - <span className="font-bold">{searchQuery}</span> - did not match any {searchType}.
        </p>
        <p>Suggestions:</p>
        <ul>
          <div className="sm:ml-4">
            <li>Make sure all words are spelled correctly.</li>
            <li>Try different search terms, last name, designation etc.</li>
          </div>
        </ul>
      </div>
    </div>;
}
import { cn } from '@/utils/classes';
import { Combobox as HUICombobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { debounce } from 'lodash';
import { Fragment, useState } from 'react';
import { type FieldError, type FieldErrorsImpl, type Merge } from 'react-hook-form';
import { Avatar } from './Avatar';
import { type SelectOption, type SelectValue } from './Select';
interface Props<T = SelectValue> {
  buttonClassName?: string;
  clearable?: boolean;
  containerClassName?: string;
  disabled?: boolean;
  fieldError?: FieldError | Merge<FieldError, FieldErrorsImpl<{}>>;
  icon?: JSX.Element;
  id?: string;
  label?: string;
  onChange: (value: T | T[] | undefined) => void;
  options: SelectOption<T>[];
  optionsClassName?: string;
  placeholder?: string;
  required?: boolean;
  showImage?: boolean;
  value: T | T[] | undefined;
  multiple?: boolean;
  className?: string;
}
export function Combobox({
  buttonClassName,
  clearable = true,
  containerClassName,
  disabled = false,
  fieldError,
  icon,
  id,
  label,
  onChange,
  options,
  optionsClassName,
  placeholder,
  required = false,
  showImage = false,
  value = '',
  className,
  multiple = false
}: Props) {
  if (!placeholder) placeholder = `Search ${label || 'Option'}`;
  const [query, setQuery] = useState('');
  const EMPTY_VALUES = [null, undefined, ''] as const;
  type EmptyValue = (typeof EMPTY_VALUES)[number];
  const values: SelectValue[] = (Array.isArray(value) ? value : [value]).filter(_value => !EMPTY_VALUES.includes(_value as EmptyValue));
  const filteredOptions = query === '' ? options : options.filter(option => {
    return option.label.toLowerCase().includes(query.toLowerCase());
  });
  const getDisplayValue = () => {
    if (showImage) {
      return '';
    } else if (values.length) {
      return values.map(_value => {
        const option = options.find(option => option.value === _value);
        return option?.label || _value;
      }).join(', ');
    } else {
      return query;
    }
  };
  const isOptionSelected = (_value: SelectValue) => values.includes(_value);
  const handleRemoveOption = (_value: Props['value']) => {
    if (multiple) {
      onChange((value as SelectValue[]).filter(__value => __value !== _value));
    } else {
      setQuery('');
      onChange(undefined);
    }
  };
  const handleOnChange = (_value: Props['value']) => {
    if (_value === undefined) return;
    if (multiple) {
      onChange(_value);
    } else {
      const option = options.find(option => option.value === _value);
      if (option) {
        setQuery(option.label);
        onChange(option.value);
      }
    }
  };
  const handleQueryChange = (_query: string) => {
    setQuery(_query);
    if (_query === '') onChange(multiple ? [] : undefined);
  };
  const shouldDisplayInput = () => {
    return disabled || multiple || !values?.length || !!getDisplayValue();
  };
  const debouncedHandleQueryChange = debounce(handleQueryChange, 200);
  return <div className={className} id={id} data-sentry-component="Combobox" data-sentry-source-file="Combobox.tsx">
      <HUICombobox as="div" value={value} onChange={handleOnChange} multiple={multiple as any} data-sentry-element="HUICombobox" data-sentry-source-file="Combobox.tsx">
        <div className={containerClassName}>
          <HUICombobox.Label as="label" className="mb-2 block text-sm font-medium leading-3 text-gray-900" data-sentry-element="unknown" data-sentry-source-file="Combobox.tsx">
            {label}
            {required && ' *'}
          </HUICombobox.Label>
          <div className="relative flex rounded-md border border-gray-300">
            <HUICombobox.Button as="button" className={cn('relative inset-y-0 w-full cursor-pointer rounded-md bg-white pr-8 text-left align-middle text-gray-900 focus:outline-none focus:ring-2 focus:ring-podi-primary sm:text-sm', {
            'focus-ring-0 pointer-events-none bg-gray-50 shadow-none ring-0': disabled,
            'pl-10': icon // Add padding to prevent overlap when icon is used
          }, showImage ? 'flex' : 'flex-1', buttonClassName)} data-sentry-element="unknown" data-sentry-source-file="Combobox.tsx">
              {showImage && <div className="flex flex-row items-center justify-start gap-2 p-1">
                  {values.map(_value => {
                const option = options.find(option => option.value === _value);
                return option ? <div key={`chip_${_value}`} className="flex flex-row items-center justify-between gap-2 rounded-md bg-gray-100 p-2">
                        <Avatar className="h-4 w-4" image={option.image} name={option?.label || ''} />
                        <div className="text-sm">{option.label}</div>
                        <div className="flex items-center" onClick={() => handleRemoveOption(_value)}>
                          <XMarkIcon className={cn('h-4 w-4 border-gray-300 text-base')} aria-hidden="true" />
                        </div>
                      </div> : null;
              })}
                </div>}
              <div className="flex flex-row items-center justify-between">
                <HUICombobox.Input as="input" className={cn('w-full rounded-md border-none pl-3 text-sm leading-6 text-gray-900 focus:ring-0', shouldDisplayInput() ? 'block' : 'hidden', {
                'bg-gray-50': disabled
              })} displayValue={getDisplayValue} onChange={event => debouncedHandleQueryChange(event.target.value)} placeholder={placeholder} data-sentry-element="unknown" data-sentry-source-file="Combobox.tsx" />
                <span className="pointer-events-none absolute inset-y-0 right-0 flex cursor-pointer items-center pr-2">
                  <ChevronUpDownIcon className={cn('h-5 w-5 text-base', {
                  'text-gray-400': disabled
                })} aria-hidden="true" data-sentry-element="ChevronUpDownIcon" data-sentry-source-file="Combobox.tsx" />
                </span>
              </div>
            </HUICombobox.Button>
            {clearable && !disabled && !!getDisplayValue() && <button className="absolute inset-y-0 right-8 flex items-center" onClick={() => handleQueryChange('')}>
                <XMarkIcon className={cn('h-5 w-8 border-r-[1px] border-gray-300 text-base')} aria-hidden="true" />
              </button>}
            <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" data-sentry-element="Transition" data-sentry-source-file="Combobox.tsx">
              <HUICombobox.Options as="div" className={cn(`absolute z-20 mt-10 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`, optionsClassName)} data-sentry-element="unknown" data-sentry-source-file="Combobox.tsx">
                {filteredOptions.length === 0 && query !== '' ? <div className="relative cursor-default select-none px-4 py-2 text-gray-700">Nothing found.</div> : (filteredOptions || []).map(option => <HUICombobox.Option as="div" key={`option_${option.value}`} value={option.value} disabled={option.disabled}>
                      {({
                  active
                }) => <div className={cn(active ? 'cursor-pointer bg-podi-primary text-white' : disabled ? 'cursor-not-allowed text-slate-400' : 'text-gray-900', 'relative flex w-full select-none flex-row items-center justify-between px-3 py-2')}>
                          <div className="flex flex-row items-center justify-start gap-2">
                            {showImage && <Avatar className="h-8 w-8" image={option.image} name={option?.label || ''} />}
                            <div>
                              <span className={cn(isOptionSelected(option.value) ? 'font-semibold' : 'font-normal', 'block')}>{option.label}</span>
                              {option.subLabel && <small className={cn(isOptionSelected(option.value) ? 'font-semibold' : 'font-normal', 'block')}>{option.subLabel}</small>}
                            </div>
                          </div>
                          {isOptionSelected(option.value) && <span className={cn(active ? 'text-white' : 'text-podi-primary', 'flex items-center pr-4')}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>}
                        </div>}
                    </HUICombobox.Option>)}
              </HUICombobox.Options>
            </Transition>
          </div>
        </div>
      </HUICombobox>
      {fieldError && <p className="!mt-0 !p-1 !text-xs !text-red-500">{fieldError.message}</p>}
    </div>;
}